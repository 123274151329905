/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { FadeInText, Panel } from "components/atoms/FadeInText/FadeInText";
import React from "react";

type Props = {
  panels: Panel[];
  delayTimeSec?: number;
  cssOverride?: SerializedStyles;
};

export const FadeInTexts: React.FC<Props> = ({
  panels,
  delayTimeSec = 0.2,
  cssOverride,
}: Props) => {
  return (
    <div css={cssOverride}>
      {panels.map((item, index) => {
        index = index + 1;
        return (
          <FadeInText
            key={index}
            index={index}
            delayTimeSec={delayTimeSec}
            panel={item}
          />
        );
      })}
    </div>
  );
};
