/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SymbolLogo } from "components/atoms/SymbolLogo/SymbolLogo";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

type Props = {
  status: number | string | React.ReactNode;
  message: string | React.ReactNode;
};
export const ErrorHero: React.FC<Props> = ({ status, message }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <h1
        css={css`
          margin-bottom: ${defaultTheme.spacing.xxs};
          ${mixin.text.engTitle};
        `}
      >
        {status}
      </h1>
      <h3
        css={css`
          margin-bottom: ${defaultTheme.spacing.xxs};
          letter-spacing: ${defaultTheme.letterSpacing.xs};
        `}
      >
        {message}
      </h3>
      <div css={css``}>
        <a href="/">
          <SymbolLogo />
        </a>
      </div>
    </div>
  );
};
