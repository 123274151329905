import React from "react";

/**
 * example.
 * const { locatonPath, scrollToWindowTop } = useWindowScrollToTop();
    React.useEffect(() => {
      scrollToWindowTop();
    }, [locatonPath]);
*/
export const useWindowScrollToTop = (): {
  locatonPath: string;
  scrollToWindowTop: () => void;
} => {
  const [locatonPath, setPath] = React.useState(window.location.pathname);
  const listenToPopstate = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
  };

  // スクロール位置をページ最上部まで戻す
  const scrollToWindowTop = React.useCallback((): void => {
    window.scrollTo(0, 0);
  }, []);

  // エフェクト時にイベントリスナーを設定
  React.useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);

  return {
    locatonPath,
    scrollToWindowTop,
  };
};
