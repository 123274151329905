/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";

export const noCursorStyles = css`
  html,
  body,
  a {
    cursor: auto;
    @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
      cursor: none;
    }
  }
`;

type Props = {
  cssOverride?: SerializedStyles;
  color?: string;
  size?: number;
  hoverColor?: string;
  hoverSize?: number;
  ref?: React.RefObject<HTMLDivElement>;
};

export const CursorIndicator: React.FC<Props> = ({
  cssOverride,
  color = "#666666",
  size = 12,
  hoverColor = "#333333",
  hoverSize = 48,
  ref,
}) => {
  return (
    <div
      ref={ref}
      id="cursor"
      css={css`
        transform: translate(0, 0);
        pointer-events: none;
        position: fixed;
        top: -4px; //座標調節（カーソル位置と円の中心を合わせる）
        left: -4px; //座標調節（カーソル位置と円の中心を合わせる）
        width: ${size}px; //カーソルの直径
        height: ${size}px; //カーソルの直径
        background-color: ${color};
        border-radius: 50%;
        z-index: 999;
        transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s;
        ${cssOverride};

        &.hov_ {
          top: -12px; //大きくなった分の座標調節
          left: -12px; //大きくなった分の座標調節
          width: ${hoverSize}px; //カーソルの直径
          height: ${hoverSize}px; //カーソルの直径
          background-color: ${hoverColor};
          opacity: 0.7;
        }
      `}
    />
  );
};
