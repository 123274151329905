/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FadeInScaleImage } from "components/atoms/FadeInScaleImage/FadeInScaleImage";
import { Panel } from "components/atoms/FadeInText/FadeInText";
import { TitleAndPanels } from "components/molecules/TitleAndPanels/TitleAndPanels";
import React from "react";
import { Link } from "react-router-dom";
import { defaultTheme } from "styles/theme";
import { FullScreenCenteredWrapper } from "utils/Wrapper/FullScreenCenteredWrapper/FullScreenCenteredWrapper";

export type FullScreenCenteredHeroProps = {
  title: string;
  requireSrc: string;
  alt: string;
  href: string;
  panels: Panel[];
  isAnchorLink?: boolean;
  animate?: boolean;
};

type Props = FullScreenCenteredHeroProps;

export const FullScreenCenteredHero: React.FC<Props> = ({
  requireSrc,
  alt,
  title,
  href,
  panels,
  isAnchorLink = false,
  animate = false,
}) => {
  return (
    <FullScreenCenteredWrapper>
      <div
        css={css`
          position: relative;

          max-width: 100%;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            max-width: 1000px;
            width: 100%;
          }
        `}
      >
        {isAnchorLink ? (
          <a href={href} target="_blank" rel="noreferrer">
            <FadeInScaleImage src={requireSrc} alt={alt} animate={animate} />
          </a>
        ) : (
          <Link to={href}>
            <FadeInScaleImage src={requireSrc} alt={alt} animate={animate} />
          </Link>
        )}

        <div
          css={css`
            position: absolute;
            top: 90%;

            // サイドマージン
            margin: 0 1.5rem;
            @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
              margin: 0 3.75rem;
            }
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              margin: 0 0;
              margin: 0 3.75rem;
            }
          `}
        >
          <TitleAndPanels animate={animate} title={title} panels={panels} />
        </div>
      </div>
    </FullScreenCenteredWrapper>
  );
};
