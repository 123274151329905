/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { SlashLineSeparator } from "../SlashSeparator/SlashLineSeparator";

export const PageIndicator: React.FC<{
  currentPage: number;
  totalPages: number;
}> = ({ currentPage, totalPages }) => {
  return (
    <div>
      <span
        css={css`
          ${mixin.changeThemeFontSize};
          font-family: "Raleway";
          font-weight: 700;

          font-size: 1.5rem;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            font-size: 2rem;
          }
        `}
      >
        0{currentPage + 1}
      </span>
      <SlashLineSeparator mx="0.5rem" size={0.75} />
      <span
        css={css`
          ${mixin.changeThemeFontSize};
          font-family: "Raleway";
          font-weight: 700;

          font-size: 0.75rem;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            font-size: 1rem;
          }
        `}
      >
        0{totalPages}
      </span>
    </div>
  );
};
