/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import React from "react";
import { LineSeparator } from "../LineSeparator/LineSeparator";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  mx?: string;
  size?: number;
  color?: string;
};

export const VerticalLineSeparator: React.FC<Props> = ({
  className,
  cssOverride,
  mx = "0.25em",
  size = 1,
  color = "currentColor",
}) => {
  return (
    <LineSeparator
      className={className}
      cssOverride={cssOverride}
      mx={mx}
      angle={180}
      size={size}
      color={color}
    />
  );
};
