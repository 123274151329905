/** @jsxImportSource @emotion/react */
import { css, Keyframes, SerializedStyles } from "@emotion/react";
import { useEffect, useState } from "react";

type FillMode =
  | "none"
  | "forwards"
  | "backwards"
  | "both"
  | "initial"
  | "inherit";

type CommonAnimationOptions = {
  keyframes: Keyframes;
  durationSec: number;
  fillMode: FillMode;
  delaySec: number;
};

const generateCommonAnimation = ({
  keyframes,
  durationSec,
  fillMode,
  delaySec,
}: CommonAnimationOptions): SerializedStyles => css`
  animation-name: ${keyframes};
  animation-duration: ${durationSec}s;
  animation-fill-mode: ${fillMode};
  animation-delay: ${delaySec}s;
`;

type StyleType = {
  initialStyle: SerializedStyles;
  showStyle: SerializedStyles;
  hideStyle: SerializedStyles;
  fillMode?: FillMode;
};

type GenerateStylesOptions = {
  commonStyles: SerializedStyles;
  showKeyframes: Keyframes;
  hideKeyframes: Keyframes;
  durationSec: number;
  fillMode: FillMode;
  delaySec: number;
};

const generateStyles = ({
  commonStyles,
  showKeyframes,
  hideKeyframes,
  durationSec,
  fillMode,
  delaySec,
}: GenerateStylesOptions): StyleType => {
  return {
    initialStyle: css`
      display: none;
    `,
    showStyle: css`
      ${commonStyles};
      ${generateCommonAnimation({
        keyframes: showKeyframes,
        durationSec,
        fillMode,
        delaySec,
      })};
    `,
    hideStyle: css`
      ${commonStyles};
      ${generateCommonAnimation({
        keyframes: hideKeyframes,
        durationSec,
        fillMode,
        delaySec,
      })};
    `,
  };
};

type AnimationOptions = {
  show: boolean;
  commonStyles: SerializedStyles;
  showKeyframes: Keyframes;
  hideKeyframes: Keyframes;
  durationSec?: number;
  fillMode?: FillMode;
  delaySec?: number;
};

export const useAnimation = ({
  show,
  commonStyles,
  showKeyframes,
  hideKeyframes,
  durationSec = 1.0,
  fillMode = "forwards",
  delaySec = 0,
}: AnimationOptions) => {
  const [animationStyles, setAnimationStyles] = useState<SerializedStyles>(
    generateStyles({
      commonStyles,
      showKeyframes,
      hideKeyframes,
      durationSec,
      fillMode,
      delaySec,
    })["initialStyle"]
  );
  const [initialShow, setInitialShow] = useState(true);

  useEffect(() => {
    if (initialShow === true && show === true) {
      // 初期表示から初めてOpenされた場合
      setInitialShow(false);
      setAnimationStyles(
        generateStyles({
          commonStyles,
          showKeyframes,
          hideKeyframes,
          durationSec,
          fillMode,
          delaySec,
        })["showStyle"]
      );
      return;
    }

    if (initialShow === false && show === true) {
      // 初期表示以降にOpenされた場合
      setAnimationStyles(
        generateStyles({
          commonStyles,
          showKeyframes,
          hideKeyframes,
          durationSec,
          fillMode,
          delaySec,
        })["showStyle"]
      );
      return;
    }

    if (initialShow === false && show === false) {
      // 初期表示以降にCloseされた場合
      setAnimationStyles(
        generateStyles({
          commonStyles,
          showKeyframes,
          hideKeyframes,
          durationSec,
          fillMode,
          delaySec,
        })["hideStyle"]
      );
      return;
    }
  }, [initialShow, show]);

  return {
    animationStyles,
  };
};
