/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SymbolLogo } from "components/atoms/SymbolLogo/SymbolLogo";
import { LogoHero } from "components/organisms/LogoHero/LogoHero";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { mixin } from "styles/mixin";
import { darkTheme, defaultTheme, Theme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";
import { BaseInner } from "utils/Wrapper/BaseInner/BaseInner";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { FullScreenCenteredWrapper } from "utils/Wrapper/FullScreenCenteredWrapper/FullScreenCenteredWrapper";

export type SplashScreenTemplateSlotProps = {
  hero: React.ComponentProps<typeof LogoHero>;
  innerOpacity?: number;
};

type Props = {
  id: string;
  slotProps: SplashScreenTemplateSlotProps;
  mediaQuery: MediaQuery;
  theme: Theme;
};

export const SplashScreenTemplate: React.FC<Props> = ({
  id,
  slotProps,
  mediaQuery,
  theme,
}) => {
  const { dark = false, slotProps: logoHeroSlotProps } = slotProps.hero;

  return (
    <BaseWrapper id={id}>
      <FullScreenCenteredWrapper
        cssOverride={css`
          ${mixin.changeThemeBgColor};
          background-color: ${darkTheme.palette.background};
          z-index: ${defaultTheme.zIndex.splashScreen};
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `}
      >
        <BaseInner>
          <FadeInOnScrollByComponent
            delay={defaultTheme.delay.medium}
            direction="spot"
          >
            <SymbolLogo
              dark
              darkColor={darkTheme.palette.gray.text}
              size={mediaQuery.pc ? 180 : 150}
              cssOverride={css`
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.15;
              `}
            />
          </FadeInOnScrollByComponent>
          <div
            css={css`
              opacity: ${slotProps.innerOpacity ?? 1};
            `}
          >
            <LogoHero
              dark={dark}
              slotProps={logoHeroSlotProps}
              mediaQuery={mediaQuery}
            />
          </div>
        </BaseInner>
      </FullScreenCenteredWrapper>
    </BaseWrapper>
  );
};
