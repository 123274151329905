/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  mx?: string;
  angle?: number;
  size?: number;
  color?: string;
};

export const LineSeparator: React.FC<Props> = ({
  className,
  cssOverride,
  mx = "0.25em",
  angle = 180,
  size = 1,
  color = "currentColor",
}) => {
  return (
    <div
      className={"" + (className ? ` ${className}` : "")}
      css={[
        cssOverride,
        css`
          display: inline-block;
          border: none;
          font-size: inherit;

          width: ${size}px;
          height: ${size}em;
          background-color: ${color};

          transform: rotate(${angle}deg);
          margin: 0 ${mx};
        `,
      ]}
    />
  );
};
