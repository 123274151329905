/** @jsxImportSource @emotion/react */
import { ThemeProvider } from "@emotion/react";
import { CursorIndicatorContainer } from "components/atoms/CursorIndicator/CursorIndicatorContainer";
import { FullScreenLoadingPage } from "components/pages/FullScreenLoadingPage/FullScreenLoadingPage";
import { HomePage } from "components/pages/HomePage/HomePage";
import { NotFoundPage } from "components/pages/NotFoundPage/NotFoundPage";
import { SplashScreenPage } from "components/pages/SplashScreenPage/SplashScreenPage";
import { AnimatePresence } from "framer-motion";
import { usePageTracking } from "hooks/usePageTracking";
import { useRouter } from "hooks/useRouter";
import { RootProvider } from "provider/RootProvider";
import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalStyles } from "styles/GlobalStyles";
import { defaultTheme, Theme } from "styles/theme";
import { constants, GA_ID } from "utils/constants";

/*** App modules - Component - Dynamic Import(LazyLoad, import function) ***/
const LazyAboutPage = lazy(() =>
  import("components/pages/AboutPage/AboutPage").then(({ AboutPage }) => ({
    default: AboutPage,
  }))
);

const LazyProductPage = lazy(() =>
  import("components/pages/ProductPage/ProductPage").then(
    ({ ProductPage }) => ({
      default: ProductPage,
    })
  )
);

const LazyProductTalkStockPage = lazy(() =>
  import("components/pages/ProductTalkStockPage/ProductTalkStockPage").then(
    ({ ProductTalkStockPage }) => ({
      default: ProductTalkStockPage,
    })
  )
);

const LazyProductPortfolioPage = lazy(() =>
  import("components/pages/ProductPortfolioPage/ProductPortfolioPage").then(
    ({ ProductPortfolioPage }) => ({
      default: ProductPortfolioPage,
    })
  )
);

const LazyProductKawaichiBlogPage = lazy(() =>
  import(
    "components/pages/ProductKawaichiBlogPage/ProductKawaichiBlogPage"
  ).then(({ ProductKawaichiBlogPage }) => ({
    default: ProductKawaichiBlogPage,
  }))
);

const LazyProgrammingOtasukePage = lazy(() =>
  import("components/pages/ProgrammingOtasukePage/ProgrammingOtasukePage").then(
    ({ ProgrammingOtasukePage }) => ({
      default: ProgrammingOtasukePage,
    })
  )
);

const LazyProductFitScreenWindowPage = lazy(() =>
  import(
    "components/pages/ProductFitScreenWindowPage/ProductFitScreenWindowPage"
  ).then(({ ProductFitScreenWindowPage }) => ({
    default: ProductFitScreenWindowPage,
  }))
);

const LazyProductShotokuPage = lazy(() =>
  import("components/pages/ProductShotokuPage/ProductShotokuPage").then(
    ({ ProductShotokuPage }) => ({
      default: ProductShotokuPage,
    })
  )
);

const LazySoftwarePage = lazy(() =>
  import("components/pages/SoftwarePage/SoftwarePage").then(
    ({ SoftwarePage }) => ({
      default: SoftwarePage,
    })
  )
);

const LazyDesignPage = lazy(() =>
  import("components/pages/DesignPage/DesignPage").then(({ DesignPage }) => ({
    default: DesignPage,
  }))
);

const LazyPrivacyPolicyPage = lazy(() =>
  import("components/pages/PrivacyPolicyPage/PrivacyPolicyPage").then(
    ({ PrivacyPolicyPage }) => ({
      default: PrivacyPolicyPage,
    })
  )
);

const LazyContactPage = lazy(() =>
  import("components/pages/ContactPage/ContactPage").then(
    ({ ContactPage }) => ({
      default: ContactPage,
    })
  )
);

/*** Routing ***/
const CustomRoutes = () => {
  const { location } = useRouter();
  usePageTracking({ gaId: GA_ID }); // MEMO: Google Analytics 計測用
  const [_, rootPath] = location.pathname.split("/");

  const DEFAULT_FIRST_LOADED = false;
  const [firstLoaded, setFirstLoaded] = useState(DEFAULT_FIRST_LOADED);

  return (
    /* MEMO: AnimatePresence: 画面遷移アニメーションコンポーネント*/
    <AnimatePresence mode="wait" initial={false}>
      {!firstLoaded && location.pathname === constants.pages.home.url && (
        <SplashScreenPage
          onAnimationComplete={() => {
            setFirstLoaded(true);
          }}
        />
      )}
      <Routes location={location} key={rootPath}>
        {/* Layout */}
        <Route path={constants.pages.home.url} element={<HomePage />} />
        <Route path={constants.pages.about.url} element={<LazyAboutPage />} />
        <Route
          path={constants.pages.product.url.index}
          element={<LazyProductPage />}
        />
        <Route
          path={constants.pages.product.url.talkstock}
          element={<LazyProductTalkStockPage />}
        />
        <Route
          path={constants.pages.product.url.portfolio}
          element={<LazyProductPortfolioPage />}
        />
        <Route
          path={constants.pages.product.url.kawaichiBlog}
          element={<LazyProductKawaichiBlogPage />}
        />
        <Route
          path={constants.pages.product.url.programmingOtasuke}
          element={<LazyProgrammingOtasukePage />}
        />
        <Route
          path={constants.pages.product.url.fitscreenwindow}
          element={<LazyProductFitScreenWindowPage />}
        />
        <Route
          path={constants.pages.product.url.shotoku}
          element={<LazyProductShotokuPage />}
        />
        <Route
          path={constants.pages.software.url}
          element={<LazySoftwarePage />}
        />
        <Route path={constants.pages.design.url} element={<LazyDesignPage />} />
        <Route
          path={constants.pages.privacyPolicy.url}
          element={<LazyPrivacyPolicyPage />}
        />
        <Route
          path={constants.pages.contact.url}
          element={<LazyContactPage />}
        />
        {/* *** No layout *** */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AnimatePresence>
  );
};

/*** Main Render ***/
export const App: React.FC = () => {
  const [theme] = useState<Theme>(defaultTheme);

  return (
    <RootProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <CursorIndicatorContainer />
          {/* MEMO: Suspense: Lazy load用*/}
          <Suspense fallback={<FullScreenLoadingPage />}>
            <CustomRoutes />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </RootProvider>
  );
};
