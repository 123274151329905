/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const SymbolLogo: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 46,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 232.59 233.46"
      >
        <g>
          <path d="M31.66,159.54A98.91,98.91,0,0,1,179.88,31.79l-2.62,3C142,4.25,88.67,4.21,53.26,34.74S10,118,35,157.4Z" />
          <path d="M115.26,205.52h-.54a98.87,98.87,0,0,1-64.18-24.06l2.62-3c35.28,30.56,88.59,30.6,124,.07s43.25-83.25,18.22-122.66l3.38-2.14a98.9,98.9,0,0,1-83.51,151.82Z" />
          <polygon
            points="124.07 120.02 94.3 145.78 124.07 171.55 124.07 120.02"
            style={{ fill: "none" }}
          />
          <polygon
            points="128.07 175.01 141.44 186.58 141.44 104.98 128.07 116.55 128.07 175.01"
            style={{ fill: "none" }}
          />
          <polygon
            points="145.44 190.05 176.57 216.99 176.57 74.57 145.44 101.52 145.44 190.05"
            style={{ fill: "none" }}
          />
          <path d="M205,26.59,79.34,134.9V53.3h-4v85L39,169.68l2.61,3,33.74-29.08v81.54h4v-85l1.19-1,100,86.59V52.93l27-23.31Zm-80.94,145L94.3,145.78,124.07,120Zm17.38,15L128.07,175V116.55L141.44,105ZM176.57,217,145.44,190V101.52l31.13-26.94Z" />
        </g>
      </svg>
    </div>
  );
};
