import React from "react";
import { Link } from "react-router-dom";
import { CustomScroll } from "utils/Wrapper/Scroll/CustomScroll";
import { isMatchPathname } from "utils/location";

// ドメイン名と同じパスならScrollLink、違うならRouterLinkをレンダリング
type ToLinkProps = {
  children: React.ReactNode;
  pathname: string;
  scrollToID: string;
};
export const ToLink: React.FC<ToLinkProps> = ({
  children,
  pathname,
  scrollToID,
}) => {
  return isMatchPathname(pathname) ? (
    <CustomScroll toId={scrollToID}>{children}</CustomScroll>
  ) : (
    <Link to={pathname}>{children}</Link>
  );
};
