/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactComponent as DrumLogo } from "assets/images/logo/drum.svg";
import { GitHubIcon } from "components/atoms/GitHubIcon/GitHubIcon";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";

const styles = {
  icon: css`
    ${mixin.hoverOpacityEffect};
  `,
};

export type SNSSymbolIconProps = {
  github: {
    href: string;
  };
};

export type SNSSymbolIconsProps = {
  color?: string;
  size?: "small" | "medium";
  iconProps: SNSSymbolIconProps;
};

export const SNSSymbolIcons: React.FC<SNSSymbolIconsProps> = ({
  color = "#666666",
  size = "medium",
  iconProps,
}: SNSSymbolIconsProps) => {
  const { github } = iconProps;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${defaultTheme.spacing.xs};
      `}
    >
      {/* githubアイコン */}
      <a href={github.href} css={styles.icon} target="_blank" rel="noreferrer">
        <GitHubIcon color={color} size={size === "small" ? 18 : 22} />
      </a>

      {/* drumアイコン */}
      <a
        href={constants.links.kawaichidrums}
        css={styles.icon}
        target="_blank"
        rel="noreferrer"
      >
        <DrumLogo
          css={css`
            display: flex;
            align-items: center;
            width: ${size === "small" ? 18 : 22}px;
            height: auto;
            fill: ${color};
          `}
        />
      </a>
    </div>
  );
};
