/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { Panel } from "components/atoms/FadeInText/FadeInText";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInByComponent } from "utils/Animation/FadeInByComponent";
import { FadeInTexts } from "../FadeInTexts/FadeInTexts";

type TitleAndPanelsProps = {
  animate: boolean;
  title: string;
  panels: Panel[];
};

export const TitleAndPanels: React.FC<TitleAndPanelsProps> = ({
  animate,
  title,
  panels,
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `}
  >
    <FadeInByComponent direction="bottom" positionY={40} animate={animate}>
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: flex-start;
          text-align: left;
        `}
      >
        <EngTitle
          cssOverride={css`
            ${mixin.changeThemeFontSize};

            // 単語単位で折り返す
            word-break: normal;
            line-height: 1 !important;

            display: flex;
            justify-content: flex-start;

            font-size: 10vw;
            @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
              font-size: 10vw;
            }
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              font-size: 5.5rem;
            }

            margin-bottom: 1rem;
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              margin-bottom: 1rem;
            }
          `}
        >
          {title}
        </EngTitle>
      </div>
    </FadeInByComponent>
    <div
      css={css`
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      `}
    >
      <FadeInTexts
        panels={panels}
        delayTimeSec={0}
        cssOverride={css`
          display: flex;
          flex-wrap: wrap;
        `}
      />
    </div>
  </div>
);
