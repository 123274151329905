/** @jsxImportSource @emotion/react */
import { css, keyframes, SerializedStyles } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import { UnderLineButton } from "components/atoms/UnderLineButton/UnderLineButton";

const styles = css`
  width: fit-content;
  font-size: 0.875rem;
  line-height: 2rem;
  opacity: 0;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const fadeInAnimation = (fadeInTime = 2) => {
  const _fadeInTime = fadeInTime + "s";
  return css`
    /*** transition|animation ***/
    animation-name: ${fadeIn};
    animation-duration: ${_fadeInTime}; /* アニメーションの長さ */
    animation-fill-mode: forwards; /* アニメーション終了時のスタイルをアニメーション終了後でも適用させる */
  `;
};

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20%); // スライドの移動量
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
const slideInFromLeftAnimation = (duration = "0.5s") => {
  return css`
    /*** transition|animation ***/
    animation-name: ${slideInFromLeft};
    animation-duration: ${duration}; /* アニメーションの長さ */
    animation-fill-mode: forwards; /* アニメーション終了時のスタイルをアニメーション終了後でも適用させる */
  `;
};

export type FadeSlideInUnderLineTextSlotProps = {
  text?: {
    style?: SerializedStyles;
  };
};

type Props = {
  cssOverride?: SerializedStyles;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  fadeInTime?: number;
  slideInTime?: string;
  slotProps?: FadeSlideInUnderLineTextSlotProps;
  animate?: boolean;
};

export const FadeSlideInUnderLineText: React.FC<Props> = ({
  cssOverride,
  onClick,
  children,
  fadeInTime = 2.1,
  slideInTime = "0.5s",
  slotProps,
  animate = true,
}: Props) => {
  return (
    <span
      css={[
        cssOverride,
        styles,
        animate ? fadeInAnimation(fadeInTime) : undefined,
      ]}
    >
      <UnderLineButton
        onClick={onClick}
        cssOverride={
          animate ? slideInFromLeftAnimation(slideInTime) : undefined
        }
      >
        <EngTitle cssOverride={slotProps?.text?.style}>{children}</EngTitle>
      </UnderLineButton>
    </span>
  );
};
