/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";

type Props = {
  id: string;
  children: React.ReactNode;
  cssOverride?: SerializedStyles;
};

export const BaseWrapper: React.FC<Props> = ({ id, children, cssOverride }) => {
  return (
    <div
      id={id}
      css={[
        cssOverride,
        css`
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        `,
      ]}
    >
      {children}
    </div>
  );
};
