/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import React from "react";

type FadeInByComponentProps = {
  children: React.ReactNode;
  duration?: number;
  direction?: "spot" | "bottom";
  positionY?: number;
  cssOverride?: SerializedStyles;
  animate?: boolean;
};

export const FadeInByComponent: React.FC<FadeInByComponentProps> = ({
  children,
  duration = 1,
  direction = "spot",
  positionY = 50,
  cssOverride,
  animate = false,
}) => {
  const getStyles = (direction: string, animate: boolean, duration: number) => {
    let style: React.CSSProperties = {
      opacity: animate ? 1 : 0,
      transition: `opacity ${duration}s ease-in-out`,
    };

    if (direction === "bottom") {
      style = {
        ...style,

        transform: animate ? "translateY(0)" : `translateY(${positionY}px)`,
        transition: `opacity ${duration}s ease-in-out, transform ${duration}s ease-in-out`,
      };
    }
    return style;
  };

  return (
    <div style={getStyles(direction, animate, duration)} css={cssOverride}>
      {children}
    </div>
  );
};
