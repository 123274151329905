import { motion } from "framer-motion";
import React from "react";

type Props = {
  children: React.ReactNode;
  duration?: number;
};
export const FadeIn: React.FC<Props> = ({
  children,
  duration = 0.5,
}: Props) => {
  return (
    <motion.div
      animate={{
        x: 0,
        opacity: 1,
      }}
      initial={{
        x: 0,
        opacity: 0,
      }}
      exit={{
        x: 0,
        y: 0,
        opacity: 0,
      }}
      transition={{
        duration,
      }}
    >
      {children}
    </motion.div>
  );
};
