/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
  color?: string;
  size?: number; // 線の太さ
  direction?: "left" | "center" | "right" | "left-to-right" | "right-to-left";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const styles = (props: Props) => {
  const directionStyles =
    props.direction === "left"
      ? css`
          &::after {
            transform-origin: left top;
          }
        `
      : props.direction === "center"
      ? css`
          &::after {
            transform-origin: center top;
          }
        `
      : props.direction === "right"
      ? css`
          &::after {
            transform-origin: right top;
          }
        `
      : props.direction === "left-to-right"
      ? css`
          &::after {
            transform-origin: right top;
          }
          &:hover::after {
            transform-origin: left top;
          }
        `
      : props.direction === "right-to-left"
      ? css`
          &::after {
            transform-origin: left top;
          }
          &:hover::after {
            transform-origin: right top;
          }
        `
      : "";
  const [transitionTime] = ["0.3s"];

  return css`
    ${directionStyles}

    width: fit-content; // 文字の幅にwidthを合わせる

    cursor: pointer;
    position: relative;

    transition: ${transitionTime} ease;

    &:hover {
      color: ${props.color};

      /*** Animation ***/
      transition: ${transitionTime} ease;
    }

    &::after {
      /*** Content box ***/
      content: "";
      width: 100%;
      height: ${props.size}px; /* 下線のサイズ */
      background: ${props.color};

      /*** Position ***/
      position: absolute;
      left: 0;
      bottom: 0px; /* 下線の上下位置 */

      /*** Animation ***/
      transform: scale(0, 1); /* アンダーラインの縮尺比率。ホバー前はx方向に0 */
      transition: transform ${transitionTime};
    }

    &:hover::after {
      /*** Animation ***/
      transform: scale(1, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
    }
  `;
};

export const UnderLineButton: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  color = "#888",
  size = 2,
  direction = "left",
  ...rest
}) => {
  return (
    <button
      {...rest}
      css={[styles({ color, size, direction }), cssOverride]}
      className={`${className ? className : ""}`}
    >
      {children}
    </button>
  );
};
