/** @jsxImportSource @emotion/react */
import { ErrorHero } from "components/organisms/ErrorHero/ErrorHero";
import React from "react";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";
import { FullScreenCenteredWrapper } from "utils/Wrapper/FullScreenCenteredWrapper/FullScreenCenteredWrapper";

type Props = {
  status: number;
  message: string;
};
export const ErrorTemplate: React.FC<Props> = ({ status, message }) => {
  return (
    <BaseWrapper id="error">
      <FullScreenCenteredWrapper>
        <ErrorHero status={status} message={message} />
      </FullScreenCenteredWrapper>
    </BaseWrapper>
  );
};
