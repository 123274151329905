/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: React.ReactNode;
  width?: number;
  height?: number;
  radius?: number | string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  textColor?: string;
  type?: "button" | "submit" | "reset";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const styles = (props: Props) => {
  return css`
    ${mixin.disableLineHeight};
    border-radius: ${typeof props.radius === "number"
      ? `${props.radius}px`
      : props.radius};
    display: block;
    width: fit-content;
    padding: calc(${props.height}px) calc(${props.width}px);
    box-sizing: border-box;
    background-color: ${props.backgroundColor};
    color: ${props.textColor};
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      background-color: ${props.backgroundColorHover};
    }
  `;
};

export const FillRadiusButton: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  width = 30,
  height = 18,
  radius = 40,
  backgroundColor = "#444444",
  backgroundColorHover = "#555555",
  textColor = "#ffffff",
  type = "button",
  ...rest
}) => {
  return (
    <button
      {...rest}
      type={type}
      css={[
        styles({
          width,
          height,
          radius,
          backgroundColor,
          backgroundColorHover,
          textColor,
        }),
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      {children}
    </button>
  );
};
