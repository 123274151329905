/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  children: React.ReactNode;
  bgColor?: string;
  cssOverride?: SerializedStyles;
  pt?: string;
  pb?: string;
};

export const FullWidthOuter: React.FC<Props> = ({
  children,
  bgColor = "#FFFFFF",
  cssOverride,
  pt = "0",
  pb = "0",
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          ${mixin.changeThemeBgColor};
          width: 100%;
          background-color: ${bgColor};
          padding-top: ${pt};
          padding-bottom: ${pb};
        `,
      ]}
    >
      {children}
    </div>
  );
};
