/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Copyright } from "components/atoms/Copyright/Copyright";
import { SelfIntroductionText } from "components/atoms/SelfIntroductionText/SelfIntroductionText";
import { FadeInUnderLineListType } from "components/molecules/FadeInUnderLineList/FadeInUnderLineList";
import { SNSTextIconsType } from "components/molecules/SNSTextIcons/SNSTextIcons";
import { Footer, FooterSlotProps } from "components/organisms/Footer/Footer";
import { Header } from "components/organisms/Header/Header";
import { NavMenuV3 } from "components/organisms/NavMenuV3/NavMenuV3";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeIn } from "utils/Animation/FadeIn";
import { BaseWrapper } from "utils/Wrapper/BaseWrapper/BaseWrapper";

export type FadeInOneColumnLayoutTemplateSlotProps = {
  header: {
    logo: {
      dark: boolean;
      show: boolean;
    };
    hamburgerButton: {
      onClick: React.MouseEventHandler<HTMLButtonElement>;
      open: boolean;
      color: string;
      openColor: string;
    };
    changeThemeButton: {
      dark?: boolean;
      styleProps?: {
        light?: {
          color?: string;
        };
        dark?: {
          color?: string;
        };
      };
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
  };
  navMenu: {
    show: boolean;
    logo: {
      onLogoClick: () => void;
    };
    overlay: {
      onOverlayClick: () => void;
    };
    navItems: {
      items: FadeInUnderLineListType[];
      onNavItemClick: () => void;
    };
    footer: {
      iconProps: SNSTextIconsType;
      uiLibraryLink: string;
    };
  };
  footer: FooterSlotProps;
};

export type HiddenFooterOption = {
  hiddenFooter?: boolean;
  hiddenFixedFooter?: boolean;
};

export type FadeInOneColumnLayoutTemplateProps = {
  slotProps: FadeInOneColumnLayoutTemplateSlotProps;
  children: React.ReactNode;
  duration?: number;
  mediaQuery: MediaQuery;
} & HiddenFooterOption;

export const FadeInOneColumnLayoutTemplate: React.FC<FadeInOneColumnLayoutTemplateProps> =
  ({
    slotProps,
    children,
    duration = 0.5,
    mediaQuery,
    hiddenFooter = false,
    hiddenFixedFooter = false,
  }: FadeInOneColumnLayoutTemplateProps) => {
    const FixedFooter = () => (
      <div>
        <div
          css={css`
            position: fixed;
            bottom: ${defaultTheme.spacing.header.top};

            left: ${defaultTheme.spacing.base.x.mobile};
            @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
              left: ${defaultTheme.spacing.base.x.pc};
            }
          `}
        >
          <SelfIntroductionText
            cssOverride={css`
              ${mixin.text.note};
              @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
                ${mixin.text.description};
              }

              color: ${defaultTheme.palette.gray.text};
              /*color: ${slotProps.footer.wrapper.textColor};*/
              display: flex;
              justify-content: center;
              position: relative;
            `}
          />
        </div>
        <div
          css={css`
            position: fixed;
            bottom: ${defaultTheme.spacing.header.top};

            right: ${defaultTheme.spacing.base.x.mobile};
            @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
              right: ${defaultTheme.spacing.base.x.pc};
            }
          `}
        >
          <Copyright
            cssOverride={css`
              ${mixin.text.note};
              @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
                ${mixin.text.description};
              }

              color: ${defaultTheme.palette.gray.text};
              /*color: ${slotProps.footer.wrapper.textColor};*/
            `}
          />
        </div>
      </div>
    );

    return (
      <FadeIn duration={duration}>
        <BaseWrapper id="layout">
          <header>
            <Header
              id="header"
              slotProps={slotProps.header}
              mediaQuery={mediaQuery}
            />
            <nav>
              <NavMenuV3
                show={slotProps.navMenu.show}
                slotProps={slotProps.navMenu}
              />
            </nav>
          </header>
          <main>{children}</main>
          {!hiddenFooter && (
            <footer>
              <Footer
                id="footer"
                slotProps={{
                  ...slotProps.footer,
                  pageTop: {
                    show: slotProps.footer?.pageTop?.show,
                    bgColor: slotProps.footer?.pageTop?.bgColor,
                    color: slotProps.footer?.pageTop?.color,
                    cssOverride: slotProps.footer?.pageTop?.cssOverride,
                    styleType: slotProps.footer?.pageTop?.styleType,
                  },
                  wrapper: {
                    bgColor: slotProps.footer.wrapper.bgColor,
                    textColor: slotProps.footer.wrapper.textColor,
                    style: css`
                      padding-top: ${defaultTheme.spacing.footer.top};
                      padding-bottom: ${defaultTheme.spacing.footer.bottom};
                    `,
                  },
                }}
                mediaQuery={mediaQuery}
              />
            </footer>
          )}
          {!hiddenFixedFooter && <FixedFooter />}
        </BaseWrapper>
      </FadeIn>
    );
  };
