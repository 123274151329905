/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { FadeSlideInUnderLineText } from "components/atoms/FadeSlideInUnderLineText/FadeSlideInUnderLineText";
import { useSequentialAnimation } from "hooks/useSequentialAnimation";
import { ToLink } from "utils/ToLink";
import { FadeInUnderLineTextSlotProps } from "../../atoms/FadeInUnderLineText/FadeInUnderLineText";

export type FadeInUnderLineListType =
  | {
      path: string;
      scrollToID: string;
      children: React.ReactNode;
    }
  | {
      path: string;
      children: React.ReactNode;
      scrollToID?: undefined;
    };

type Props = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  items: FadeInUnderLineListType[];
  delayTime?: number;
  fadeInTime?: number;
  slideInTime?: string;
  slotProps?: {
    container?: {
      style?: SerializedStyles;
    };
    item?: FadeInUnderLineTextSlotProps;
  };
};
export const FadeInUnderLineList: React.FC<Props> = ({
  className,
  onClick,
  items,
  delayTime = 200,
  fadeInTime = 2.1,
  slideInTime = "0.5s",
  slotProps,
}: Props) => {
  const { animationFlags } = useSequentialAnimation({
    length: items.length,
    delayTime,
  });

  return (
    <ul
      className={"" + (className ? ` ${className}` : "")}
      css={[
        slotProps?.container?.style,
        css`
          display: flex;
          flex-direction: column;
        `,
      ]}
    >
      {items.map((item, index) => {
        return item.scrollToID ? (
          <li
            key={index}
            css={css`
              width: fit-content;
            `}
          >
            <ToLink pathname={item.path} scrollToID={item.scrollToID}>
              <FadeSlideInUnderLineText
                onClick={onClick}
                fadeInTime={fadeInTime}
                slideInTime={slideInTime}
                slotProps={slotProps?.item}
                animate={animationFlags[index]}
              >
                {item.children}
              </FadeSlideInUnderLineText>
            </ToLink>
          </li>
        ) : (
          <li
            key={index}
            css={css`
              width: fit-content;
            `}
          >
            <a href={item.path} target="_blank" rel="noreferrer">
              <FadeSlideInUnderLineText
                onClick={onClick}
                fadeInTime={fadeInTime}
                slideInTime={slideInTime}
                slotProps={slotProps?.item}
                animate={animationFlags[index]}
              >
                {item.children}
              </FadeSlideInUnderLineText>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
