/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { ArrowIcon } from "components/atoms/ArrowIcon/ArrowIcon";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FullWidthOuter } from "utils/Wrapper/FullWidthOuter/FullWidthOuter";
import { CustomScroll } from "utils/Wrapper/Scroll/CustomScroll";

export type PageTopProps = {
  bgColor?: string;
  color?: string;
  cssOverride?: SerializedStyles;
  styleType?: "iconText" | "icon";
};

export const PageTop: React.FC<PageTopProps> = ({
  bgColor = "#444444",
  color = "#ffffff",
  cssOverride,
  styleType = "iconText",
}) => {
  const basePaddingY =
    styleType === "iconText"
      ? defaultTheme.spacing.xxs
      : defaultTheme.spacing.xs;

  return (
    <FullWidthOuter bgColor={bgColor} cssOverride={cssOverride}>
      <CustomScroll
        toWindowTop
        toId=""
        cssOverride={css`
          display: flex;
          justify-content: center;
          align-items: center;
          gap: ${defaultTheme.spacing.xxs};
          margin: 0 auto;
          padding: ${basePaddingY} 0;
        `}
      >
        {styleType === "iconText" ? (
          <>
            <ArrowIcon
              color={color}
              direction="up"
              weight={2.5}
              size={3.5}
              css={css`
                position: relative;
                top: 2px;
              `}
            />
            <span
              css={css`
                ${mixin.changeThemeColor};
                ${mixin.text.body};
                color: ${color};
              `}
            >
              このページのトップへ
            </span>
          </>
        ) : (
          <ArrowIcon
            color={color}
            direction="up"
            weight={2.35}
            size={6}
            css={css`
              position: relative;
              top: 4px;
            `}
          />
        )}
      </CustomScroll>
    </FullWidthOuter>
  );
};
