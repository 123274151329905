/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useTheme } from "hooks/useTheme";
import React, { useEffect } from "react";
import { CursorIndicator } from "./CursorIndicator";

const useCursorIndicator = () => {
  /* オリジナルマウスカーソル */
  useEffect(() => {
    // FIXME: cursorがnullの場合があるため修正する(なぜか404ページでは正常動作する)
    //カーソル用のdivタグを取得
    const cursor = document.getElementById("cursor");

    const handleMouseMove = (e: MouseEvent) => {
      if (cursor) {
        cursor.style.transform =
          "translate(" + e.clientX + "px, " + e.clientY + "px)";
      }
    };

    const handleMouseOver = () => {
      if (cursor) {
        cursor.classList.add("hov_");
      }
    };

    const handleMouseOut = () => {
      if (cursor) {
        cursor.classList.remove("hov_");
      }
    };

    //マウスに追従するイベントリスナーを追加
    document.addEventListener("mousemove", handleMouseMove);

    //リンクにホバー時のイベントリスナーを追加
    const linkElem = document.querySelectorAll("a");
    linkElem.forEach((link) => {
      link.addEventListener("mouseover", handleMouseOver);
      link.addEventListener("mouseout", handleMouseOut);
    });

    // クリーンアップ: アンマウント時にイベントリスナーを削除
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      linkElem.forEach((link) => {
        link.removeEventListener("mouseover", handleMouseOver);
        link.removeEventListener("mouseout", handleMouseOut);
      });
    };
  }, []);
};

export const CursorIndicatorContainer: React.FC = () => {
  const { theme } = useTheme();
  const mediaQuery = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  useCursorIndicator();

  return (
    <CursorIndicator
      color={theme.palette.primary}
      hoverColor={theme.palette.gray.button[100]}
      size={12}
      hoverSize={48}
      cssOverride={css`
        ${mediaQuery.pc ? "" : "display: none;"}
      `}
    />
  );
};
