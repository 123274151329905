/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

const styles = css`
  ${mixin.text.description};
  letter-spacing: 0.2em;
  white-space: nowrap;
  text-transform: uppercase;
`;

type Props = {
  cssOverride?: SerializedStyles;
};

export const SelfIntroductionText: React.FC<Props> = ({ cssOverride }) => {
  return <span css={[styles, cssOverride]}>Web Engineer</span>;
};
