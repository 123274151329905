import { ThemeState } from "hooks/useTheme";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import {
  BASE_COLOR_FITSCREENWINDOW,
  defaultMode,
  defaultTheme,
} from "styles/theme";

const defaultValues: ThemeState = {
  theme: defaultTheme,
  fitScreenWindowTheme: BASE_COLOR_FITSCREENWINDOW,
  mode: defaultMode,
  active: false,
};

const themeState = atom<ThemeState>({
  key: "themeState",
  default: defaultValues,
});

export const useThemeState = () => {
  const [state, setState] = useRecoilState(themeState);
  return {
    ...state,
  };
};

export const useThemeMutators = () => {
  const setState = useSetRecoilState(themeState);

  return {
    setTheme: setState,
  };
};
