/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { css } from "@storybook/theming";
import React from "react";
import { defaultTheme } from "styles/theme";

type DiscTextProps = {
  children: React.ReactNode;
  discSize?: number;
  discColor?: string;
  cssOverride?: SerializedStyles;
};

export const DiscText: React.FC<DiscTextProps> = ({
  children,
  discSize = 6,
  discColor = "#666666",
  cssOverride,
}) => {
  return (
    <span
      css={[
        cssOverride,
        css`
          display: inline-block;

          line-height: 1.5rem;
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            line-height: 1.5rem;
          }

          position: relative;
          padding-left: 20px; // 点とテキストの間隔を確保

          &::before {
            content: ""; // 実際のテキストは不要なので空にする
            position: absolute;
            left: 0;
            top: 50%; // 親要素に対して中央に配置

            transform: translateY(-50%); // Y軸方向の中心を調整
            width: ${discSize}px; // 点の幅
            height: ${discSize}px; // 点の高さ
            background-color: ${discColor}; // 点の色
            border-radius: 50%; // 円形にする
          }
        `,
      ]}
    >
      {children}
    </span>
  );
};
