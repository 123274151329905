import { useEffect, useState } from "react";

export type MediaQuery = {
  pc: boolean;
  mobile: boolean;
};

type Options = {
  pcBreakPoint?: string | number;
  mobileBreakPoint?: string | number;
};

export const useMediaQuery = ({
  pcBreakPoint = "768px",
  mobileBreakPoint = "767px",
}: Options = {}) => {
  const [mediaQuery, setMediaQuery] = useState<MediaQuery>({
    pc: window.matchMedia(`screen and (min-width: ${pcBreakPoint})`).matches,
    mobile: window.matchMedia(`screen and (max-width: ${mobileBreakPoint})`)
      .matches,
  });

  useEffect(() => {
    const onResize = () => {
      setMediaQuery({
        pc: window.matchMedia(`screen and (min-width: ${pcBreakPoint})`)
          .matches,
        mobile: window.matchMedia(`screen and (max-width: ${mobileBreakPoint})`)
          .matches,
      });
    };

    window.addEventListener("resize", onResize);
    window.addEventListener("load", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("load", onResize);
    };
  }, [pcBreakPoint, mobileBreakPoint]);

  return mediaQuery;
};
