/** @jsxImportSource @emotion/react */
import { css, keyframes, SerializedStyles } from "@emotion/react";
import React, { useMemo } from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  size?: number;
  fromColor?: string;
  toColor?: string;
  duration?: number;
  disableAnimation?: boolean;
};

export const GradationDivider: React.FC<Props> = ({
  className,
  cssOverride,
  size = 3,
  fromColor = "#888",
  toColor = "#888",
  duration = 1.5, // sec
  disableAnimation = false,
}) => {
  const styles = useMemo(() => {
    const slideIn = keyframes`
    0% {
      transform-origin: left top;
      transform: scale(0, 1);
    }
    100% {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  `;

    return {
      slideAnimation: css`
        /*** transition|animation ***/
        animation-name: ${slideIn};
        animation-duration: ${duration}s; /* アニメーションの長さ */
        animation-fill-mode: forwards; /* アニメーション終了時のスタイルをアニメーション終了後でも適用させる */
      `,
      borderGradation: css`
        border-bottom: ${size}px solid;
        border-image: linear-gradient(
          to right,
          ${fromColor} 0%,
          ${toColor} 100%
        );
        border-image-slice: 1;
      `,
    };
  }, [size, fromColor, toColor, duration]);

  return (
    <div
      css={[
        css`
          ${disableAnimation ? "animation: none;" : styles.slideAnimation};
          ${styles.borderGradation};
        `,
        cssOverride,
      ]}
      className={"" + (className ? ` ${className}` : "")}
    />
  );
};
