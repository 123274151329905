type SmoothScrollOption = {
  activeClass: string;
  spy: boolean;
  smooth: boolean;
  offset: number;
  duration: number;
};
export const smoothScrollOption: SmoothScrollOption = {
  activeClass: "active",
  spy: true,
  smooth: true,
  offset: -70,
  duration: 750,
};
