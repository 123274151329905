/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { EngTitle } from "components/atoms/EngTitle/EngTitle";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";

const StyledEngTitle: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <EngTitle
      cssOverride={css`
        ${mixin.text.siteMapItem};
      `}
    >
      {children}
    </EngTitle>
  );
};

type SiteMapItemProps = {
  engTitle: string;
  items: React.ReactNode[];
};

export const SiteMapItem: React.FC<SiteMapItemProps> = ({
  engTitle,
  items,
}) => {
  return (
    <>
      <div>
        <StyledEngTitle>{engTitle}</StyledEngTitle>
      </div>
      <div
        css={css`
          ${mixin.text.description};
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: ${defaultTheme.spacing.xxs};
          word-break: break-all;
        `}
      >
        {items}
      </div>
    </>
  );
};
