/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";

import React, { ReactNode } from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
  color?: string;
  size?: number;
  weight?: number;
  direction?: "up" | "left" | "down" | "right";
};

/*** CSS Style ***/
const styles = (props: Props) => {
  const directionStyles =
    props.direction === "up"
      ? css`
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        `
      : props.direction === "left"
      ? css`
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        `
      : props.direction === "down"
      ? css`
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        `
      : props.direction === "right"
      ? css`
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        `
      : {};

  return css`
    ${mixin.changeTheme}
    ${directionStyles}
    border: solid ${props.color};
    border-width: 0 ${props.weight}px ${props.weight}px 0;
    display: inline-block;
    padding: ${props.size}px;
  `;
};

export const ArrowIcon: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  color = "#444444",
  size = 3,
  weight = 3,
  direction = "right",
}: Props) => {
  return (
    <div
      css={[
        styles({
          color,
          size,
          weight,
          direction,
        }),
        cssOverride,
      ]}
      className={"" + (className ? ` ${className}` : "")}
    >
      {children}
    </div>
  );
};
