/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React from "react";
import { defaultTheme } from "styles/theme";

type Props = {
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  px?:
    | {
        pc: string;
        mobile: string;
      }
    | "0";
  pt?: string;
  pb?: string;
  mb?: string;
  centered?: boolean;
  cssOverride?: SerializedStyles;
};

export const BaseInner: React.FC<Props> = ({
  children,
  width = "fit-content",
  maxWidth = defaultTheme.maxWidth.base,
  px = {
    pc: defaultTheme.spacing.base.x.pc,
    mobile: defaultTheme.spacing.base.x.mobile,
  },
  pt,
  pb,
  mb,
  centered = true,
  cssOverride,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          ${centered && `margin: 0 auto;`}
          margin-bottom: ${mb};
          width: ${width};
          max-width: ${maxWidth};
          padding-top: ${pt};
          padding-bottom: ${pb};

          padding-left: ${px === "0" ? "0" : px.mobile};
          padding-right: ${px === "0" ? "0" : px.mobile};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            padding-left: ${px === "0" ? "0" : px.pc};
            padding-right: ${px === "0" ? "0" : px.pc};
          }
        `,
      ]}
    >
      {children}
    </div>
  );
};
