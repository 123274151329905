/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import React from "react";

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const getStyles = (nth: number, delayTimeSec: number) => {
  return css`
    opacity: 0;
    animation-name: ${fadeIn};
    animation-duration: 1s; /* アニメーションの長さ */
    animation-fill-mode: forwards; /* アニメーション終了時のスタイルをアニメーション終了後でも適用させる */
    animation-delay: calc(${delayTimeSec}s * ${nth});
  `;
};

export type Panel = {
  value: React.ReactNode;
};

type Props = {
  panel: Panel;
  index: number;
  delayTimeSec?: number;
};

export const FadeInText: React.FC<Props> = ({
  panel,
  index,
  delayTimeSec = 0.2,
}: Props) => {
  return (
    <span key={index} css={getStyles(index, delayTimeSec)}>
      {panel.value}
    </span>
  );
};
