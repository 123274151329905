/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  SplashScreenTemplate,
  SplashScreenTemplateSlotProps,
} from "components/templates/SplashScreenTemplate/SplashScreenTemplate";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useTheme } from "hooks/useTheme";
import { useEffect, useMemo, useState } from "react";
import {
  ANIMATAION_DURATION_SM,
  ANIMATAION_DURATION_SPLASH_SCREEN,
  convertDurationToSeconds,
  mixin,
} from "styles/mixin";
import { lightTheme } from "styles/theme";
import { constants } from "utils/constants";

const delayTimeSec = convertDurationToSeconds(ANIMATAION_DURATION_SM); // リストアイテムの表示ごとに加算されていくディレイ
type Props = {
  onAnimationComplete: () => void;
};

export const SplashScreenPage: React.FC<Props> = ({ onAnimationComplete }) => {
  const { mode, theme, switchToDarkTheme, switchToLightTheme } = useTheme();
  const mediaQuery = useMediaQuery({
    pcBreakPoint: theme.breakpoints.md,
  });

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    switchToDarkTheme();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplashScreen(false);
      switchToLightTheme();
      onAnimationComplete();
    }, ANIMATAION_DURATION_SPLASH_SCREEN);
    return () => clearTimeout(timer);
  }, []);

  const slotProps: SplashScreenTemplateSlotProps = useMemo(
    () => ({
      hero: {
        delayTimeSec,
        dark: mode === "dark",
        slotProps: {
          SelfIntroductionText: {
            textColor: theme.palette.text,
          },
        },
        mediaQuery,
      },
      innerOpacity: showSplashScreen ? 1 : 0,
    }),
    [mode]
  );

  return (
    <div
      css={css`
        ${mixin.changeThemeBgColor};
        ${mixin.changeThemeOpacity};
        opacity: 1;
        background-color: ${lightTheme.palette.background};
      `}
    >
      <SplashScreenTemplate
        id={constants.pages.home.id}
        slotProps={slotProps}
        mediaQuery={mediaQuery}
        theme={theme}
      />
    </div>
  );
};
