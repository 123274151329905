/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";

type FadeInOnScrollByComponentProps = {
  children: React.ReactNode;
  delay?: number;
  threshold?: number;
  duration?: number;
  direction?: "spot" | "bottom";
  positionY?: number;
  cssOverride?: SerializedStyles;
};

export const FadeInOnScrollByComponent: React.FC<FadeInOnScrollByComponentProps> =
  ({
    children,
    delay = 0,
    threshold = 0.25,
    duration = 1,
    direction = "spot",
    positionY = 50,
    cssOverride,
  }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            if (delay > 0) {
              setTimeout(() => {
                setIsVisible(true);
              }, delay);
            } else {
              setIsVisible(true);
            }
            observer.disconnect();
          }
        },
        { threshold } // 0.25の場合、要素が25%表示されたときにコールバックが呼ばれる
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        observer.disconnect();
      };
    }, [delay, threshold]);

    const getStyles = (
      direction: string,
      isVisible: boolean,
      duration: number
    ) => {
      let style: React.CSSProperties = {
        opacity: isVisible ? 1 : 0,
        transition: `opacity ${duration}s ease-in-out`,
      };

      if (direction === "bottom") {
        style = {
          ...style,

          transform: isVisible ? "translateY(0)" : `translateY(${positionY}px)`,
          transition: `opacity ${duration}s ease-in-out, transform ${duration}s ease-in-out`,
        };
      }
      return style;
    };

    return (
      <div
        ref={ref}
        style={getStyles(direction, isVisible, duration)}
        css={cssOverride}
      >
        {children}
      </div>
    );
  };
