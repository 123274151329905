/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { SymbolLogo } from "components/atoms/SymbolLogo/SymbolLogo";
import React from "react";
import { constants } from "utils/constants";
import { ToLink } from "utils/ToLink";

export type LinkSymbolLogoProps = {
  dark?: boolean;
  cssOverride?: SerializedStyles;
};

const styles = {
  logoImage: css`
    cursor: pointer;
    max-height: 50px;
  `,
};

export const LinkSymbolLogo: React.FC<LinkSymbolLogoProps> = ({
  dark = false,
  cssOverride,
}) => {
  return (
    <div css={[cssOverride]}>
      <ToLink
        pathname={constants.pages.home.url}
        scrollToID={constants.pages.home.scrollToID}
      >
        <SymbolLogo dark={dark} css={[styles.logoImage]} />
      </ToLink>
    </div>
  );
};
