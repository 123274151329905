/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { css } from "@storybook/theming";
import React from "react";
import { mixin } from "styles/mixin";
import { NewWindowIcon } from "../NewWindowIcon/NewWindowIcon";

type NewWindowTextProps = {
  cssOverride?: SerializedStyles;
  color?: string;
  size?: number;
  weight?: number;
  hideIcon?: boolean;
  children: React.ReactNode;
};

export const NewWindowText: React.FC<NewWindowTextProps> = ({
  cssOverride,
  color,
  size,
  weight,
  hideIcon,
  children,
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: flex;
          align-items: flex-end;
          flex-wrap: nowrap;
        `,
      ]}
    >
      <span
        css={css`
          line-height: 1 !important;
        `}
      >
        {children}
        {!hideIcon && (
          <NewWindowIcon
            size={size}
            weight={weight}
            color={color}
            cssOverride={css`
              ${mixin.changeThemeStroke};
              display: inline-block;
              // MEMO: テキストラインを視覚上に揃えるため調整
              position: relative;
              top: 4px;
            `}
          />
        )}
      </span>
    </div>
  );
};
