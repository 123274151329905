/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { ReactComponent as StorybookDarkLogo } from "assets/images/logo/logo-storybook-dark.svg";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { ComponentIcon } from "../ComponentIcon/ComponentIcon";
import { FillRadiusButton } from "../FillRadiusButton/FillRadiusButton";
import { NewWindowIcon } from "../NewWindowIcon/NewWindowIcon";

type Props = {
  cssOverride?: SerializedStyles;
};

export const ComponentLibraryButton: React.FC<Props> = ({ cssOverride }) => {
  return (
    <div css={[cssOverride, css``]}>
      <FillRadiusButton
        radius={defaultTheme.borderRadius.base}
        height={10}
        width={12}
        backgroundColor={defaultTheme.palette.gray.button[200]}
        backgroundColorHover={defaultTheme.palette.gray.button[100]}
        textColor={defaultTheme.palette.contrastText}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ComponentIcon
            size={22}
            css={css`
              position: relative;
              top: -1px;
              display: inline-block;
              margin-right: 6px;
            `}
            color={defaultTheme.palette.contrastText}
          />
          <div
            css={css`
              ${mixin.text.body};
              ${mixin.disableLineHeight};
              margin-right: 0.5rem;
              font-weight: 700;
              white-space: nowrap;
            `}
          >
            コンポーネントライブラリ
          </div>
          <NewWindowIcon
            size={20}
            color={defaultTheme.palette.common.white}
            cssOverride={css`
              position: relative;
              top: 8px;
              left: 2px;
            `}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            padding-left: 1.5rem;
          `}
        >
          <span
            css={css`
              ${mixin.text.note};
              margin-right: 0.3rem;
            `}
          >
            (with
          </span>
          <StorybookDarkLogo
            css={css`
              width: 80px;
              height: auto;
            `}
          />
          <span
            css={css`
              ${mixin.text.note};
            `}
          >
            )
          </span>
        </div>
      </FillRadiusButton>
    </div>
  );
};
