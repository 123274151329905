import { useCallback, useEffect } from "react";
import { useThemeMutators, useThemeState } from "stores/themeStore";
import {
  Mode,
  Theme,
  darkTheme,
  lightTheme,
  mobileSpacing,
  pcSpacing,
} from "styles/theme";
import { useMediaQuery } from "./useMediaQuery";

export type ThemeState = {
  theme: Theme;
  fitScreenWindowTheme: string;
  mode: Mode;
  active: boolean;
};

export const useTheme = () => {
  const { active, fitScreenWindowTheme, mode, theme } = useThemeState();
  const { setTheme } = useThemeMutators();

  const { pc } = useMediaQuery({ pcBreakPoint: theme.breakpoints.md });
  useEffect(() => {
    if (pc) {
      setTheme((prev) => ({
        ...prev,
        theme: { ...prev.theme, ...pcSpacing },
      }));
    } else {
      setTheme((prev) => ({
        ...prev,
        theme: { ...prev.theme, ...mobileSpacing },
      }));
    }
  }, [pc]);

  const switchToLightTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      mode: "light",
      theme: lightTheme,
    }));
  }, [setTheme]);

  const switchToDarkTheme = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      mode: "dark",
      theme: darkTheme,
    }));
  }, [setTheme]);

  const updateFitScreenWindowTheme = useCallback(
    (color: string) => {
      setTheme((prev) => ({
        ...prev,
        fitScreenWindowTheme: color,
      }));
    },
    [setTheme]
  );

  const toggleThemeState = useCallback(() => {
    setTheme((prev) => ({
      ...prev,
      active: !prev.active,
    }));

    if (active) {
      switchToLightTheme();
    } else {
      switchToDarkTheme();
    }
  }, [active, switchToLightTheme, setTheme]);

  return {
    theme,
    fitScreenWindowTheme,
    mode,
    active,
    switchToLightTheme,
    switchToDarkTheme,
    updateFitScreenWindowTheme,
    toggleThemeState,
  };
};
