/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  color?: string;
  size?: number;
};

const styles = (props: Props) => {
  return css`
    stroke: ${props.color};
    width: ${props.size}px;
    height: ${props.size}px;
  `;
};

export const ComponentIcon: React.FC<Props> = ({
  className,
  cssOverride,
  color = "#444444",
  size = 24,
}) => {
  return (
    <div
      css={[styles({ color, size }), cssOverride]}
      className={`${className ? className : ""}`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.65 93.1">
        <path
          d="M35.16 10.09h22.73v22.73H35.16z"
          transform="rotate(45 46.525 21.455)"
          className="cls-1"
        />
        <path
          d="M60.51 35.45h22.73v22.73H60.51z"
          transform="rotate(45 71.875 46.815)"
          className="cls-1"
        />
        <path
          d="M9.8 35.45h22.73v22.73H9.8z"
          transform="rotate(45 21.165 46.815)"
          className="cls-1"
        />
        <path
          d="M35.16 60.81h22.73v22.73H35.16z"
          transform="rotate(45 46.525 72.175)"
          className="cls-1"
        />
        <style>
          {`.cls-1 { fill: none; stroke: ${color}; stroke-miterlimit: 10; stroke-width: ${
            (size / 24) * 6
          }px; }`}
        </style>
      </svg>
    </div>
  );
};
