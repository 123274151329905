/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import { noCursorStyles } from "components/atoms/CursorIndicator/CursorIndicator";
import { useTheme } from "hooks/useTheme";
import { useMemo } from "react";
import { mixin } from "./mixin";
import { defaultTheme } from "./theme";

export const GlobalStyles: React.FC = () => {
  const { theme } = useTheme();

  const globalStyles = useMemo(
    () => css`
      ${noCursorStyles};

      html,
      body {
        // MEMO: モバイルデバイスでの「プル・トゥ・リフレッシュ」やスクロールバウンスを防ぐ(強く下スクロールした際のページ更新を防ぐ)
        overscroll-behavior-y: none;
      }

      html {
        /* MEMO: ページ初期読み込み時の一瞬の白背景表示防止(bodyの{mixin.changeTheme};によってフェードインされるため) */
        background-color: ${defaultTheme.palette.background};
      }

      body {
        ${mixin.changeTheme};
        background-color: ${theme.palette.background};
        color: ${theme.palette.text};
        font-family: "Montserrat", "roboto", "Noto Sans JP",
          "Hiragino Kaku Gothic ProN", "Hiragino Sans", "meiryo", "arial",
          sans-serif;
        line-height: 3rem;
      }
    `,
    [mixin, theme]
  );

  return <Global styles={globalStyles} />;
};
