/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { DiscText } from "components/atoms/DiscText/DiscText";
import { defaultTheme } from "styles/theme";

type DiscListProps = {
  items: React.ReactNode[];
  cssOverride?: SerializedStyles;
  slotProps?: {
    item?: {
      style?: SerializedStyles;
    };
  };
};

export const DiscList: React.FC<DiscListProps> = ({
  items,
  cssOverride,
  slotProps,
}) => {
  return (
    <ul
      css={[
        css`
          width: 100%;

          display: flex;
          flex-wrap: wrap;
          align-items: center;

          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            display: block;
          }
        `,
        cssOverride,
      ]}
    >
      {items.map((item, index) => (
        <li
          key={index}
          css={css`
            display: flex;
            width: auto;
            padding-right: ${defaultTheme.spacing.sm};
            @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
              width: 100%;
              padding-right: 0;
            }
          `}
        >
          <DiscText
            cssOverride={css`
              ${slotProps?.item?.style}
            `}
          >
            {item}
          </DiscText>
        </li>
      ))}
    </ul>
  );
};
