import { memo } from "react";
import { BarLoader } from "react-spinners";
import { defaultTheme } from "styles/theme";
import { FullScreenCenteredWrapper } from "utils/Wrapper/FullScreenCenteredWrapper/FullScreenCenteredWrapper";

export const FullScreenLoadingPage = memo(() => {
  return (
    <FullScreenCenteredWrapper>
      <BarLoader height={3} color={defaultTheme.palette.primary} width={160} />
    </FullScreenCenteredWrapper>
  );
});
