/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FadeInByComponent } from "utils/Animation/FadeInByComponent";

export const FadeInScaleImage: React.FC<{
  src: string;
  alt: string;
  animate: boolean;
}> = ({ src, alt, animate }) => (
  <FadeInByComponent direction="spot" animate={animate}>
    <img
      src={src}
      alt={alt}
      css={css`
        max-width: 100%;
        max-height: 400px;

        transition-duration: 300ms;
        opacity: 0.75;
        transform: scale(1);
        filter: brightness(0.9);
        &:hover {
          transition-duration: 300ms;
          opacity: 1;
          transform: scale(1.1);
          filter: brightness(0.95);
        }
      `}
    />
  </FadeInByComponent>
);
