/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import React, { ReactNode, useCallback } from "react";
import { Link as Scroll, animateScroll } from "react-scroll";
import { smoothScrollOption } from "utils/Wrapper/Scroll/smoothScrollOption";

type Props = {
  toWindowTop?: boolean;
  toId: string;
  children: ReactNode;
  className?: string;
  cssOverride?: SerializedStyles;
};
export const CustomScroll: React.FC<Props> = ({
  toWindowTop,
  toId,
  children,
  className,
  cssOverride,
}) => {
  const scrollToTop = useCallback(() => {
    animateScroll.scrollToTop(smoothScrollOption);
  }, []);

  return toWindowTop ? (
    <button
      css={[cssOverride]}
      className={"" + (className ? ` ${className}` : "")}
      style={{ width: "fit-content" }}
      onClick={scrollToTop}
    >
      {children}
    </button>
  ) : (
    <button
      css={[cssOverride]}
      className={"" + (className ? ` ${className}` : "")}
      style={{ width: "fit-content" }}
    >
      <Scroll
        style={{ cursor: "pointer" }}
        to={toId}
        activeClass={smoothScrollOption.activeClass}
        spy={smoothScrollOption.spy}
        smooth={smoothScrollOption.smooth}
        offset={smoothScrollOption.offset}
        duration={smoothScrollOption.duration}
      >
        {children}
      </Scroll>
    </button>
  );
};
