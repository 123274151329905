/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

export type LinkTextStyleType = "default" | "reverse";

export type LinkTextItem = {
  href: string;
  value: ReactNode;
};

type LinkTextItemProps = {
  item: LinkTextItem;
  cssOverride?: SerializedStyles;
  mode?: "anchor" | "router";
  styleType?: LinkTextStyleType;
};

export const LinkText: React.FC<LinkTextItemProps> = ({
  item,
  cssOverride,
  mode = "anchor",
  styleType = "default",
}) => {
  const styles =
    styleType === "default"
      ? css`
          text-decoration-line: underline;
          &:hover {
            text-decoration-line: none;
          }
        `
      : css`
          text-decoration-line: none;
          &:hover {
            text-decoration-line: underline;
          }
        `;

  return mode === "anchor" ? (
    <a
      css={[cssOverride, styles]}
      href={item.href}
      target="_blank"
      rel="noreferrer"
    >
      {item.value}
    </a>
  ) : (
    <Link css={[cssOverride, styles]} to={item.href}>
      {item.value}
    </Link>
  );
};
