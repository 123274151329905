/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PageIndicator } from "components/atoms/PageIndicator/PageIndicator";
import {
  FullScreenCenteredHero,
  FullScreenCenteredHeroProps,
} from "components/organisms/FullScreenCenteredHero/FullScreenCenteredHero";
import ReactPageScroller from "react-page-scroller";
import { ANIMATAION_DURATION_SM } from "styles/mixin";
import { defaultTheme } from "styles/theme";

type HomeTemplateProps = {
  currentPage: number;
  pagesNumbers: number[];
  handleBeforePageChange: (number: number) => void;
  handleAfterPageChange: (number: number) => void;
  handleScrollUnavailable: () => void;
  screenComponentItems: FullScreenCenteredHeroProps[];
  animate: boolean[];
};

export const HomeTemplate: React.FC<HomeTemplateProps> = ({
  currentPage,
  pagesNumbers,
  handleBeforePageChange,
  handleAfterPageChange,
  handleScrollUnavailable,
  screenComponentItems,
  animate,
}) => {
  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 50%;
          left: ${defaultTheme.spacing.base.x.mobile};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            left: ${defaultTheme.spacing.base.x.pc};
          }

          transform: translateY(-50%);
          z-index: ${defaultTheme.zIndex.pageNumber};
        `}
      >
        <PageIndicator
          currentPage={currentPage}
          totalPages={pagesNumbers.length}
        />
      </div>
      <ReactPageScroller
        onBeforePageScroll={handleBeforePageChange}
        renderAllPagesOnFirstRender
        pageOnChange={handleAfterPageChange}
        customPageNumber={currentPage}
        animationTimer={ANIMATAION_DURATION_SM}
        animationTimerBuffer={500} // スクロールでページを切り替えてからスクロールを禁止させる時間
        handleScrollUnavailable={handleScrollUnavailable}
        //blockScrollUp={currentPage === 0}
        //blockScrollDown={currentPage === pagesNumbers.length - 1}
        transitionTimingFunction="ease-in-out"
      >
        {screenComponentItems.map((item, index) => (
          <FullScreenCenteredHero
            key={index}
            animate={animate[index]}
            title={item.title}
            requireSrc={item.requireSrc}
            alt={item.alt}
            href={item.href}
            isAnchorLink={item.isAnchorLink}
            panels={item.panels}
          />
        ))}
      </ReactPageScroller>
    </div>
  );
};
