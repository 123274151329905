/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";
import { constants } from "utils/constants";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
};

export const Copyright: React.FC<Props> = ({ className, cssOverride }) => {
  return (
    <p
      className={`${className ? className : ""}`}
      css={[
        css`
          ${mixin.text.note};
        `,
        cssOverride,
      ]}
    >
      {constants.copyRight.text}
    </p>
  );
};
