/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { DarkThemeIcon } from "components/atoms/DarkThemeIcon/DarkThemeIcon";
import { LightThemeIcon } from "components/atoms/LightThemeIcon/LightThemeIcon";
import React from "react";
import { mixin } from "styles/mixin";

type ChangeThemeButtonProps = {
  className?: string;
  cssOverride?: SerializedStyles;
  size?: number;
  dark?: boolean;
  styleProps?: {
    light?: {
      color?: string;
    };
    dark?: {
      color?: string;
    };
  };
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ChangeThemeButton: React.FC<ChangeThemeButtonProps> = ({
  className,
  cssOverride,
  size = 30,
  dark = false,
  styleProps = {
    light: {
      color: "#444444",
    },
    dark: {
      color: "#eeeeee",
    },
  },
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`${className ? className : ""}`}
      css={[
        cssOverride,
        css`
          ${mixin.hoverOpacityEffect};
        `,
      ]}
    >
      {dark ? (
        <DarkThemeIcon
          cssOverride={mixin.changeThemeFill}
          size={size}
          color={styleProps.dark?.color}
        />
      ) : (
        <LightThemeIcon
          cssOverride={mixin.changeThemeFill}
          size={size}
          color={styleProps.light?.color}
        />
      )}
    </button>
  );
};
