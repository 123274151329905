/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { defaultTheme } from "styles/theme";

type Columns2to6WrapperProps = {
  firstComponent?: React.ReactNode;
  secondComponent?: React.ReactNode;
  thirdComponent?: React.ReactNode;
  fourthComponent?: React.ReactNode;
  fifthComponent?: React.ReactNode;
  sixthComponent?: React.ReactNode;
  colCount?: 5 | 6;
  children?: React.ReactNode;
  cssOverride?: SerializedStyles;
  columnGap?: {
    xs?: string | number;
    sm?: string | number;
    md?: string | number;
    lg?: string | number;
  };
};

export const Columns2to6Wrapper: React.FC<Columns2to6WrapperProps> = ({
  firstComponent,
  secondComponent,
  thirdComponent,
  fourthComponent,
  fifthComponent,
  sixthComponent,
  colCount = 6,
  children,
  cssOverride,
  columnGap = {
    xs: defaultTheme.spacing.xs,
    sm: defaultTheme.spacing.sm,
    md: defaultTheme.spacing.md,
    lg: defaultTheme.spacing.lg,
  },
}) => {
  return (
    <div
      css={[
        cssOverride,
        css`
          display: grid;

          grid-template-columns: repeat(2, 1fr);
          row-gap: ${defaultTheme.spacing.md};
          column-gap: ${columnGap.xs};
          @media screen and (min-width: ${defaultTheme.breakpoints.md}) {
            grid-template-columns: repeat(${colCount}, 1fr);
            row-gap: ${defaultTheme.spacing.lg};
            column-gap: ${columnGap.sm};
          }

          // MEMO: どのウィンドウサイズのときでも、各divが均等に縮小されるようにする
          & > div {
            min-width: 0;
          }
        `,
      ]}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          {firstComponent && <div>{firstComponent}</div>}
          {secondComponent && <div>{secondComponent}</div>}
          {thirdComponent && <div>{thirdComponent}</div>}
          {fourthComponent && <div>{fourthComponent}</div>}
          {fifthComponent && <div>{fifthComponent}</div>}
          {sixthComponent && <div>{sixthComponent}</div>}
        </>
      )}
    </div>
  );
};
