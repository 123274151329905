import { css } from "@emotion/css";
import { useCallback, useEffect, useState } from "react";

const disableScrollStyles = css`
  overflow: hidden; // スクロールを無効化
`;

export const useNavMenu = () => {
  const [open, setOpen] = useState<boolean>(false);

  // 表示の切り替え
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  // スクロール禁止をトグル
  useEffect(() => {
    if (open) {
      document.body.classList.add(disableScrollStyles);
      return;
    } else {
      document.body.classList.remove(disableScrollStyles);
    }

    return () => {
      document.body.classList.remove(disableScrollStyles);
    };
  }, [open]);

  return {
    open,
    toggleOpen,
  };
};
