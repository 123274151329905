/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  dark?: boolean;
  lightColor?: string;
  darkColor?: string;
  size?: number;
};

export const TextLogoV2: React.FC<Props> = ({
  className,
  cssOverride,
  dark = false,
  lightColor = "#444444",
  darkColor = "#ffffff",
  size = 30,
}) => {
  const color = dark ? darkColor : lightColor;
  return (
    <div
      css={[
        css`
          ${mixin.changeThemeFill};
          fill: ${color};
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        height={size}
        width={"100%"}
        id="1cf121ab-a6c4-40b7-a1be-75f012f4b46e"
        data-name="main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 506.32 65.45"
      >
        <polygon points="253.4 0 223.43 65.45 234.26 65.45 241.77 48.96 245.04 41.71 255.31 18.11 265.71 41.72 268.94 48.96 276.5 65.45 287.24 65.45 257.24 0 253.4 0" />
        <polygon points="48.59 0 35.43 0 9.89 25.96 9.89 0 0 0 0 65.45 9.89 65.45 9.89 38.42 11.93 36.31 35.53 65.45 48.82 65.45 18.99 29.48 48.59 0" />
        <polygon points="93.39 0 63.43 65.45 74.27 65.45 81.77 48.96 85.04 41.71 95.31 18.11 105.71 41.72 108.94 48.96 116.5 65.45 127.24 65.45 97.24 0 93.39 0" />
        <rect x="307.08" width="9.89" height="65.45" />
        <path d="M374.07.31a32.19,32.19,0,0,0-14.7,3.62,35.47,35.47,0,0,0-11.72,9.64,29,29,0,0,0-6.3,18.33A33,33,0,0,0,344,45a32.23,32.23,0,0,0,17.58,17.66,31.66,31.66,0,0,0,12.37,2.5,32.38,32.38,0,0,0,16.22-4.38l.48-.27V49l-1.56,1.3a23.89,23.89,0,0,1-15.49,6,21.81,21.81,0,0,1-16-6.93,24,24,0,0,1-6.82-17.49,22.23,22.23,0,0,1,6.82-16.09,21.9,21.9,0,0,1,16-6.87A23.23,23.23,0,0,1,389,14.69L390.61,16V4.62l-.48-.27A32.08,32.08,0,0,0,374.07.31Z" />
        <polygon points="454.21 0 454.21 25.48 425.92 25.48 425.92 0 415.99 0 415.99 65.45 425.92 65.45 425.92 34.87 454.21 34.87 454.21 65.45 464.06 65.45 464.06 0 454.21 0" />
        <rect x="496.43" width="9.89" height="65.45" />
        <polygon points="210.39 0 193.41 43.59 176.56 0 174.08 0 157.39 43.57 140.33 0 129.54 0 155.67 65.45 159.11 65.45 175.33 23.22 191.74 65.45 195.06 65.45 221.13 0 210.39 0" />
      </svg>
    </div>
  );
};
