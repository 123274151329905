/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { LinkText, LinkTextItem } from "components/atoms/LinkText/LinkText";
import { NewWindowText } from "components/atoms/NewWindowText/NewWindowText";
import { DiscList } from "components/molecules/DiscList/DiscList";
import { SiteMapItem } from "components/molecules/SiteMapItem/SiteMapItem";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { constants } from "utils/constants";
import { Columns2to6Wrapper } from "utils/Wrapper/Columns2to6Wrapper/Columns2to6Wrapper";

const StyledLinkText: React.FC<{
  item: LinkTextItem;
  mode?: "anchor" | "router" | undefined;
}> = ({ item, mode = "router" }) => {
  return (
    <LinkText
      cssOverride={css`
        width: fit-content;
      `}
      styleType="reverse"
      mode={mode}
      item={item}
    />
  );
};

const StyledListLinkText: React.FC<{
  item: LinkTextItem;
  mode?: "anchor" | "router" | undefined;
}> = ({ item, mode = "router" }) => {
  return (
    <LinkText
      cssOverride={css`
        width: 100%;
        ${mixin.text.description};
      `}
      styleType="reverse"
      mode={mode}
      item={item}
    />
  );
};

const StyledDiscList: React.FC<{ items: React.ReactNode[] }> = ({ items }) => {
  return (
    <DiscList
      slotProps={{
        item: {
          style: css`
            ${mixin.text.description};
            line-height: 1.5 !important;
            padding: ${defaultTheme.spacing.xxs} 0;
          `,
        },
      }}
      cssOverride={css`
        width: 100%;
      `}
      items={items}
    />
  );
};

const KawaichiBlogSiteMapItem: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <SiteMapItem
      engTitle="BLOG"
      items={[
        <StyledLinkText
          mode="anchor"
          item={{
            href: constants.links.kawaichiBlog,
            value: (
              <NewWindowText
                color={color}
                size={18}
                cssOverride={css`
                  // MEMO: テキストラインを視覚上に揃えるため調整
                  position: relative;
                  top: -4px;
                `}
              >
                ブログ
              </NewWindowText>
            ),
          }}
        />,
      ]}
    />
  );
};

const ContactSiteMapItem: React.FC = () => {
  return (
    <SiteMapItem
      engTitle={constants.pages.contact.title}
      items={[
        <StyledLinkText
          mode="router"
          item={{
            href: constants.pages.contact.url,
            value: "お問い合わせ",
          }}
        />,
      ]}
    />
  );
};

type SiteMapProps = {
  color?: string;
  mediaQuery: MediaQuery;
};

export const SiteMap: React.FC<SiteMapProps> = ({
  color = "#666666",
  mediaQuery,
}) => {
  return (
    <nav>
      <Columns2to6Wrapper
        colCount={mediaQuery.pc ? 5 : 6}
        cssOverride={css`
          text-align: left;
          padding: 0 ${defaultTheme.spacing.xxs};
          @media screen and (min-width: ${defaultTheme.breakpoints.sm}) {
            padding: 0 0;
          }
        `}
        firstComponent={
          <SiteMapItem
            engTitle={constants.pages.about.title}
            items={[
              <StyledLinkText
                mode="router"
                item={{
                  href: constants.pages.about.url,
                  value: "プロフィール",
                }}
              />,
            ]}
          />
        }
        secondComponent={
          <SiteMapItem
            engTitle={constants.pages.product.title}
            items={[
              <StyledLinkText
                mode="router"
                item={{
                  href: constants.pages.product.url.index,
                  value: "個人開発プロダクト",
                }}
              />,
              <StyledDiscList
                items={[
                  <StyledListLinkText
                    mode="router"
                    item={{
                      href: constants.pages.product.url.talkstock,
                      value: "トークストック",
                    }}
                  />,
                  <StyledListLinkText
                    mode="router"
                    item={{
                      href: constants.pages.product.url.portfolio,
                      value: "KAWAICHI PORTFOLIO",
                    }}
                  />,
                  <StyledListLinkText
                    mode="router"
                    item={{
                      href: constants.pages.product.url.kawaichiBlog,
                      value: "かわいちのエンジニアぶろぐ！",
                    }}
                  />,
                  <StyledListLinkText
                    mode="router"
                    item={{
                      href: constants.pages.product.url.programmingOtasuke,
                      value: "プログラミングのエラーおたすけツール！",
                    }}
                  />,
                  <StyledListLinkText
                    mode="router"
                    item={{
                      href: constants.pages.product.url.fitscreenwindow,
                      value: "FitScreenWindow",
                    }}
                  />,
                  <StyledListLinkText
                    mode="anchor"
                    item={{
                      href: constants.links.batchfilerenamer,
                      value: (
                        <NewWindowText
                          color={color}
                          size={18}
                          cssOverride={css`
                            ${mixin.text.description};
                            // MEMO: テキストラインを視覚上に揃えるため調整
                            position: relative;
                            top: -4px;
                            @media screen and (min-width: ${defaultTheme
                                .breakpoints.md}) {
                            }
                          `}
                        >
                          BatchFileRenamer
                        </NewWindowText>
                      ),
                    }}
                  />,
                  <StyledListLinkText
                    mode="router"
                    item={{
                      href: constants.pages.product.url.shotoku,
                      value: "所得拡大促進税制エクセルソフト",
                    }}
                  />,
                ]}
              />,
            ]}
          />
        }
        thirdComponent={
          <SiteMapItem
            engTitle={constants.pages.software.title}
            items={[
              <StyledLinkText
                mode="router"
                item={{
                  href: constants.pages.software.url,
                  value: "ソフトウェア",
                }}
              />,
              <StyledDiscList
                items={[
                  <LinkText
                    styleType="reverse"
                    mode="anchor"
                    item={{
                      href: constants.links.fitscreenwindow,
                      value: (
                        <NewWindowText
                          color={color}
                          size={14}
                          cssOverride={css`
                            ${mixin.text.note};
                            // MEMO: テキストラインを視覚上に揃えるため調整
                            position: relative;
                            top: -2px;
                          `}
                        >
                          FitScreenWindow 公式サイト
                        </NewWindowText>
                      ),
                    }}
                  />,
                  <LinkText
                    styleType="reverse"
                    mode="anchor"
                    item={{
                      href: constants.links.batchfilerenamer,
                      value: (
                        <NewWindowText
                          color={color}
                          size={14}
                          cssOverride={css`
                            ${mixin.text.note};
                            // MEMO: テキストラインを視覚上に揃えるため調整
                            position: relative;
                            top: -2px;
                          `}
                        >
                          BatchFileRenamer 公式サイト
                        </NewWindowText>
                      ),
                    }}
                  />,
                ]}
              />,
            ]}
          />
        }
        fourthComponent={
          <SiteMapItem
            engTitle={constants.pages.design.title}
            items={[
              <StyledLinkText
                mode="router"
                item={{
                  href: constants.pages.design.url,
                  value: "広告デザイン",
                }}
              />,
            ]}
          />
        }
        fifthComponent={
          mediaQuery.pc ? (
            <div>
              <div
                css={css`
                  margin-bottom: ${defaultTheme.spacing.sm};
                `}
              >
                <ContactSiteMapItem />
              </div>
              <div>
                <KawaichiBlogSiteMapItem color={color} />
              </div>
            </div>
          ) : (
            <ContactSiteMapItem />
          )
        }
        sixthComponent={
          !mediaQuery.pc ? <KawaichiBlogSiteMapItem color={color} /> : null
        }
      />
    </nav>
  );
};
