/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SelfIntroductionText } from "components/atoms/SelfIntroductionText/SelfIntroductionText";
import { TextLogoV2 } from "components/atoms/TextLogoV2/TextLogoV2";
import { MediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { mixin } from "styles/mixin";
import { defaultTheme } from "styles/theme";
import { FadeInOnScrollByComponent } from "utils/Animation/FadeInOnScrollByComponent";

type Props = {
  dark?: boolean;
  slotProps?: {
    SelfIntroductionText: {
      textColor: string;
    };
  };
  mediaQuery: MediaQuery;
};
export const LogoHero: React.FC<Props> = ({
  dark = false,
  slotProps,
  mediaQuery,
}: Props) => {
  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <h1>
        {/*<FadeInOnScrollByComponent
          delay={defaultTheme.delay.medium}
          direction="spot"
          positionY={14}
        >
          <SymbolLogo
            dark={dark}
            size={66}
            cssOverride={css`
              display: flex;
              justify-content: center;
              margin-bottom: ${defaultTheme.spacing.xxs};
            `}
          />
        </FadeInOnScrollByComponent>*/}
        <FadeInOnScrollByComponent
          delay={defaultTheme.delay.short}
          direction="bottom"
          positionY={14}
        >
          <TextLogoV2
            dark={dark}
            size={mediaQuery.pc ? 24 : 20}
            cssOverride={css`
              display: flex;
              justify-content: center;
              margin-bottom: ${defaultTheme.spacing.xs};
            `}
          />
        </FadeInOnScrollByComponent>
      </h1>
      <h2>
        <FadeInOnScrollByComponent
          delay={defaultTheme.delay.medium}
          direction="spot"
        >
          <SelfIntroductionText
            cssOverride={css`
              ${mixin.text.description};
              color: ${slotProps?.SelfIntroductionText.textColor};
              display: flex;
              justify-content: center;
              position: relative;
              left: 0.1rem;
            `}
          />
        </FadeInOnScrollByComponent>
      </h2>
    </section>
  );
};
