/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  color?: string;
  size?: number;
  weight?: number; // 線の太さ
};

export const NewWindowIcon: React.FC<Props> = ({
  className,
  cssOverride,
  color = "#444444",
  size = 28,
  weight = 16,
  ...rest
}) => {
  return (
    <div
      {...rest}
      css={[
        css`
          display: flex; // MEMO: 縦の余分な計算ピクセルを消す
          stroke: ${color};
        `,
        cssOverride,
      ]}
      className={`${className ? className : ""}`}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={size}
        height={size}
        viewBox="0 0 300 300"
        css={css`
          enable-background: new 0 0 300 300;
        `}
      >
        <g>
          <rect
            x="95.73"
            y="69.25"
            width="143.66"
            height="127.29"
            css={css`
              fill: none;
              stroke-width: ${weight};
              stroke-linecap: square;
              stroke-linejoin: round;
              stroke-miterlimit: 10;
            `}
          />
          <polyline
            points="60.61,153.71 60.61,230.75 138.09,230.75"
            css={css`
              fill: none;
              stroke-width: ${weight};
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-miterlimit: 10;
            `}
          />
        </g>
      </svg>
    </div>
  );
};
