/** @jsxImportSource @emotion/react */
import { css, keyframes, SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { useAnimation } from "../../../hooks/useAnimation";

type Size = "full" | number;

type Direction = "top" | "left" | "bottom" | "right" | "none";

const generateStyles = ({
  size,
  direction,
  color,
  opacityRange,
}: {
  size: Size;
  direction: Direction;
  color: string;
  opacityRange: {
    from: number;
    to: number;
  };
}) => {
  const _size = size === "full" ? 0 : size && 100 - size; // vh または vh を画面全体単位に変換するためviewport 100からマイナスさせている
  const directionStyles =
    direction === "top"
      ? {
          keyframesFrom: css`
            transform: translateY(-100vh);
          `,
          keyframesTo: css`
            transform: translateY(-${_size}vh);
          `,
          padding: css`
            padding-top: calc(100vh - ${_size}vh);
          `,
        }
      : direction === "left"
      ? {
          keyframesFrom: css`
            transform: translateX(-100vw);
          `,
          keyframesTo: css`
            transform: translateX(-${_size}vw);
          `,
          padding: css`
            padding-left: calc(100vw - ${_size}vw);
          `,
        }
      : direction === "bottom"
      ? {
          keyframesFrom: css`
            transform: translateY(100vh);
          `,
          keyframesTo: css`
            transform: translateY(${_size}vh);
          `,
          padding: css`
            padding-bottom: calc(-100vh - ${_size}vh);
          `,
        }
      : direction === "right"
      ? {
          keyframesFrom: css`
            transform: translateX(100vw);
          `,
          keyframesTo: css`
            transform: translateX(${_size}vw);
          `,
          padding: css`
            padding-right: calc(-100vw - ${_size}vw);
          `,
        }
      : direction === "none"
      ? {
          keyframesFrom: css``,
          keyframesTo: css``,
          padding: css``,
        }
      : {};

  const slideIn = keyframes`
    0% {
      opacity: ${opacityRange.from};
      ${directionStyles.keyframesFrom};
    }
    100% {
      opacity: ${opacityRange.to};
      ${directionStyles.keyframesTo};
    }
  `;

  const slideOut = keyframes`
    0% {
      opacity: ${opacityRange.to};
      ${directionStyles.keyframesTo};
    }
    100% {
      opacity: ${opacityRange.from};
      ${directionStyles.keyframesFrom};
      display: none;
    }
  `;

  return {
    slideIn,
    slideOut,
    commonStyles: css`
      background-color: ${color};
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      ${directionStyles.padding};
    `,
  };
};

type Props = {
  className?: string;
  cssOverride?: SerializedStyles;
  children?: ReactNode;
  show?: boolean;
  color?: string;
  size?: Size;
  direction?: Direction;
  duration?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  opacityRange?: {
    from: number;
    to: number;
  };
};

export const SlideOverlay: React.FC<Props> = ({
  className,
  cssOverride,
  children,
  show = false,
  color = "#222222CC",
  size = 100,
  direction = "top",
  duration = 1.5, // sec
  onClick = () => {},
  opacityRange = { from: 0, to: 1 },
}: Props) => {
  const { slideIn, slideOut, commonStyles } = generateStyles({
    size,
    direction,
    color,
    opacityRange,
  });

  const { animationStyles } = useAnimation({
    show,
    commonStyles: commonStyles,
    showKeyframes: slideIn,
    hideKeyframes: slideOut,
    durationSec: duration,
  });

  return (
    <button
      css={[
        animationStyles,
        cssOverride,
        css`
          cursor: default;
        `,
      ]}
      className={"" + (className ? ` ${className}` : "")}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
