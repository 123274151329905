import { useEffect, useState } from "react";

type Config = {
  length?: number;
  delayTime?: number;
};

export function useSequentialAnimation({
  length = 1,
  delayTime = 200,
}: Config) {
  const [animationFlags, setAnimationFlags] = useState<boolean[]>(
    Array(length).fill(false)
  );

  useEffect(() => {
    Array.from({ length }, (_, index) => {
      setTimeout(() => {
        setAnimationFlags((prevAnimate) =>
          prevAnimate.map((_, i) => (i === index ? true : prevAnimate[i]))
        );
      }, index * delayTime);
    });
  }, [length, delayTime]);

  return { animationFlags: animationFlags };
}
