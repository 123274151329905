/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import React from "react";
import { mixin } from "styles/mixin";

const styles = css`
  ${mixin.text.engTitle};
  display: inline-block;
  line-height: 1.5 !important;
  font-family: "Raleway";
  font-weight: 700;
  word-break: break-all;
`;

type Props = { children: React.ReactNode; cssOverride?: SerializedStyles };
export const EngTitle: React.FC<Props> = ({ children, cssOverride }: Props) => {
  return <span css={[styles, cssOverride]}>{children}</span>;
};
